<template>
	<modal class="NoxModalKYCSettings" name="NoxModalKYCSettings" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addKYCQuestion'">Добавление вопроса</span>
					<span v-else-if="noxType == 'addKYCAnswer'">Добавление ответа</span>
					<span v-else-if="noxType == 'editKYCQuestion'">Редактирование вопроса</span>
					<span v-else-if="noxType == 'editKYCAnswer'">Редактирование ответа</span>
					<span v-else-if="noxType == 'deleteKYCQuestion'">Удаление вопроса</span>
					<span v-else-if="noxType == 'deleteKYCAnswer'">Удаление ответа</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="['addKYCQuestion', 'editKYCQuestion'].includes(noxType)">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Статус вопроса:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxStatus)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForStatus"></toggle-button>
								<div v-html="noxAlertStatus"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Перемешивать ответы:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxIsRandom)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsRandom"></toggle-button>
								<div v-html="noxAlertIsRandom"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Текст вопроса (RU):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxQuestionRU" placeholder="текст вопроса (ru)"></textarea>
								<div v-html="noxAlertQuestionRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Текст вопроса (EN):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxQuestionEN" placeholder="текст вопроса (en)"></textarea>
								<div v-html="noxAlertQuestionEN"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Текст ответа (RU):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxAnswerRU" placeholder="текст ответа (ru)"></textarea>
								<div v-html="noxAlertAnswerRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Текст ответа (EN):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxAnswerEN" placeholder="текст ответа (en)"></textarea>
								<div v-html="noxAlertAnswerEN"></div>
							</div>
						</div>
					</div>
					<div v-else-if="['addKYCAnswer', 'editKYCAnswer'].includes(noxType)">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Верный ответ:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxIsCorrect)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsCorrect"></toggle-button>
								<div v-html="noxAlertIsCorrect"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Текст ответа (RU):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxAnswerRU" placeholder="текст ответа (ru)" autocomplete="off">
								<div v-html="noxAlertAnswerRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Текст ответа (EN):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxAnswerEN" placeholder="текст ответа (en)" autocomplete="off">
								<div v-html="noxAlertAnswerEN"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'deleteKYCQuestion'">Вы действительно хотите удалить вопрос?</div>
					<div v-else-if="noxType == 'deleteKYCAnswer'">Вы действительно хотите удалить ответ?</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="['addKYCQuestion', 'addKYCAnswer'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="['editKYCQuestion', 'editKYCAnswer'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="['deleteKYCQuestion', 'deleteKYCAnswer'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertStatus: '',
			noxAlertQuestionRU: '',
			noxAlertQuestionEN: '',
			noxAlertAnswerRU: '',
			noxAlertAnswerEN: '',
			noxAlertIsRandom: '',
			noxAlertIsCorrect: '',
			noxId: 0,
			noxQid: 0,
			noxType: '',
			noxIndex: 0,
			noxStatus: 0,
			noxQuestionRU: '',
			noxQuestionEN: '',
			noxAnswerRU: '',
			noxAnswerEN: '',
			noxIsRandom: 0,
			noxIsCorrect: 0,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxQid = event.params.qid;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxStatus = 0;
				this.noxQuestionRU = '';
				this.noxQuestionEN = '';
				this.noxAnswerRU = '';
				this.noxAnswerEN = '';
				this.noxIsRandom = 0;
				this.noxIsCorrect = 0;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editKYCQuestion') {

					this.noxStatus = this.$parent.noxKYCQuestions[this.noxIndex].status;
					this.noxQuestionRU = this.$parent.noxKYCQuestions[this.noxIndex].question_ru;
					this.noxQuestionEN = this.$parent.noxKYCQuestions[this.noxIndex].question_en;
					this.noxAnswerRU = this.$parent.noxKYCQuestions[this.noxIndex].answer_ru;
					this.noxAnswerEN = this.$parent.noxKYCQuestions[this.noxIndex].answer_en;
					this.noxIsRandom = this.$parent.noxKYCQuestions[this.noxIndex].is_random;
				}
				else if (this.noxType == 'editKYCAnswer') {

					this.noxAnswerRU = this.$parent.noxKYCAnswers[this.noxQid][this.noxIndex].answer_ru;
					this.noxAnswerEN = this.$parent.noxKYCAnswers[this.noxQid][this.noxIndex].answer_en;
					this.noxIsCorrect = this.$parent.noxKYCAnswers[this.noxQid][this.noxIndex].is_correct;
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertStatus = '';
				this.noxAlertQuestionRU = '';
				this.noxAlertQuestionEN = '';
				this.noxAlertAnswerRU = '';
				this.noxAlertAnswerEN = '';
				this.noxAlertIsRandom = '';
				this.noxAlertIsCorrect = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id вопроса.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id вопроса.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать статус вопроса.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный статус вопроса.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать перемешивание ответов вопроса.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верное перемешивание ответов вопроса.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать текст вопроса (ru).'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный текст вопроса (ru).'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать текст вопроса (en).'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верный текст вопроса (en).'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать id ответа.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верный id ответа.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать текст ответа (ru).'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать верный текст ответа (ru).'; }
				else if (i == 15) { this.noxTemp = 'Необходимо указать текст ответа (en).'; }
				else if (i == 16) { this.noxTemp = 'Необходимо указать верный текст ответа (en).'; }
				else if (i == 17) { this.noxTemp = 'Необходимо указать верный ответ.'; }
				else if (i == 18) { this.noxTemp = 'Id вопроса не найден в системе.'; }
				else if (i == 19) { this.noxTemp = 'Id ответа не найден в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addKYCQuestion', 'editKYCQuestion'].includes(_this.noxType)) {
						if (!_this.noxQuestionRU) {
							_this.noxAlertQuestionRU = _this.getError(7);
						}
						if (!_this.noxQuestionEN) {
							_this.noxAlertQuestionEN = _this.getError(9);
						}
						if (!_this.noxAnswerRU) {
							_this.noxAlertAnswerRU = _this.getError(13);
						}
						if (!_this.noxAnswerEN) {
							_this.noxAlertAnswerEN = _this.getError(15);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/kyc/questions';
							config.data = { id: _this.noxId, status: _this.noxStatus, question_ru: _this.noxQuestionRU, question_en: _this.noxQuestionEN, answer_ru: _this.noxAnswerRU, answer_en: _this.noxAnswerEN, is_random: _this.noxIsRandom };
							config.method = _this.noxType == 'addKYCQuestion' ? 'post' : 'patch';
						}
					}
					else if (['addKYCAnswer', 'editKYCAnswer'].includes(_this.noxType)) {
						if (!_this.noxAnswerRU) {
							_this.noxAlertAnswerRU = _this.getError(13);
						}
						if (!_this.noxAnswerEN) {
							_this.noxAlertAnswerEN = _this.getError(15);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/kyc/answers';
							config.data = { id: _this.noxId, qid: _this.noxQid, answer_ru: _this.noxAnswerRU, answer_en: _this.noxAnswerEN, is_correct: _this.noxIsCorrect };
							config.method = _this.noxType == 'addKYCAnswer' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deleteKYCQuestion') {
						config.url = '/v2/admin/account/kyc/questions';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}
					else if (_this.noxType == 'deleteKYCAnswer') {
						config.url = '/v2/admin/account/kyc/answers';
						config.data = { id: _this.noxId, qid: _this.noxQid };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (['addKYCQuestion', 'editKYCQuestion'].includes(_this.noxType)) {
									if (_this.noxType == 'addKYCQuestion') {
										_this.$parent.noxKYCQuestions.push(data.data);
									}
									else if (_this.noxType == 'editKYCQuestion') {
										_this.$parent.noxKYCQuestions.splice(_this.noxIndex, 1, data.data);
									}
								}
								else if (['addKYCAnswer', 'editKYCAnswer'].includes(_this.noxType)) {
									if (_this.noxType == 'addKYCAnswer') {
										if (_this.$parent.noxKYCAnswers[_this.noxQid] == undefined) {
											_this.$set(_this.$parent.noxKYCAnswers, _this.noxQid, []);
										}
										_this.$parent.noxKYCAnswers[_this.noxQid].push(data.data);
									}
									else if (_this.noxType == 'editKYCAnswer') {
										_this.$parent.noxKYCAnswers[_this.noxQid].splice(_this.noxIndex, 1, data.data);
									}
								}
								else if (_this.noxType == 'deleteKYCQuestion') {
									_this.$parent.noxKYCQuestions = data.data;
									_this.$set(_this.$parent.noxKYCAnswers, _this.noxId, []);
								}
								else if (_this.noxType == 'deleteKYCAnswer') {
									_this.$set(_this.$parent.noxKYCAnswers, _this.noxQid, data.data);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addKYCQuestion', 'editKYCQuestion', 'deleteKYCQuestion'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'STATUS_IS_EMPTY') { _this.noxAlertStatus = _this.getError(3); }
										else if (data.response.data.error == 'STATUS_NOT_VALID') { _this.noxAlertStatus = _this.getError(4); }
										else if (data.response.data.error == 'IS_RANDOM_IS_EMPTY') { _this.noxAlertIsRandom = _this.getError(5); }
										else if (data.response.data.error == 'IS_RANDOM_NOT_VALID') { _this.noxAlertIsRandom = _this.getError(6); }
										else if (data.response.data.error == 'QUESTION_RU_IS_EMPTY') { _this.noxAlertQuestionRU = _this.getError(7); }
										else if (data.response.data.error == 'QUESTION_RU_NOT_VALID') { _this.noxAlertQuestionRU = _this.getError(8); }
										else if (data.response.data.error == 'QUESTION_EN_IS_EMPTY') { _this.noxAlertQuestionEN = _this.getError(9); }
										else if (data.response.data.error == 'QUESTION_EN_NOT_VALID') { _this.noxAlertQuestionEN = _this.getError(10); }
										else if (data.response.data.error == 'ANSWER_RU_IS_EMPTY') { _this.noxAlertAnswerRU = _this.getError(13); }
										else if (data.response.data.error == 'ANSWER_RU_NOT_VALID') { _this.noxAlertAnswerRU = _this.getError(14); }
										else if (data.response.data.error == 'ANSWER_EN_IS_EMPTY') { _this.noxAlertAnswerEN = _this.getError(15); }
										else if (data.response.data.error == 'ANSWER_EN_NOT_VALID') { _this.noxAlertAnswerEN = _this.getError(16); }
									}
									else if (['addKYCAnswer', 'editKYCAnswer', 'deleteKYCAnswer'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(11); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(12); }
										else if (data.response.data.error == 'QID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'QID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'IS_CORRECT_IS_EMPTY') { _this.noxAlertIsCorrect = _this.getError(17); }
										else if (data.response.data.error == 'IS_CORRECT_NOT_VALID') { _this.noxAlertIsCorrect = _this.getError(17); }
										else if (data.response.data.error == 'ANSWER_RU_IS_EMPTY') { _this.noxAlertAnswerRU = _this.getError(13); }
										else if (data.response.data.error == 'ANSWER_RU_NOT_VALID') { _this.noxAlertAnswerRU = _this.getError(14); }
										else if (data.response.data.error == 'ANSWER_EN_IS_EMPTY') { _this.noxAlertAnswerEN = _this.getError(15); }
										else if (data.response.data.error == 'ANSWER_EN_NOT_VALID') { _this.noxAlertAnswerEN = _this.getError(16); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['editKYCQuestion', 'deleteKYCQuestion'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(18); }
									}
									else if (['editKYCAnswer', 'deleteKYCAnswer'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(19); }
										else if (data.response.data.error == 'QID_NOT_FOUND') { _this.noxAlert = _this.getError(18); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForStatus: function(event) {
				this.noxStatus = Number(event.value);
			},
			toggleChangeForIsRandom: function(event) {
				this.noxIsRandom = Number(event.value);
			},
			toggleChangeForIsCorrect: function(event) {
				this.noxIsCorrect = Number(event.value);
			},
			close: function() {
				this.$modal.hide('NoxModalKYCSettings');
			}
		}
	}
</script>
